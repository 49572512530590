<template>
	<v-container
		class="pa-0"
		fluid
	>
		<!-- 상단 비쥬얼 -->
		<div class="visual">
			<div class="wrapper">
				<HomeCustomCarefloVisual />
			</div>
		</div>

		<!-- 스티커확인문구 -->
		<div class="sticker">
			<div class="wrapper">
				<HomeCustomCarefloSticker />
			</div>
		</div>

		<!-- 그린배너 -->
		<HomeCustomCarefloGreenBanner />

		<!-- about 원케어 -->
		<div class="py-7 py-md-15 px-4 one_care">
			<div class="wrapper">
				<v-col
					class="pa-0"
					align="center"
				>
					<span class="care_tit one">원케어 One-Care</span>
				</v-col>
				<p class="mt-5 mb-4 mt-md-12 mb-md-10 sub_tit tac">원케어 제공 혜택</p>

				<HomeCustomCarefloOneBenefit />
				<HomeCustomCarefloOneAccodian />
			</div>
		</div>

		<!-- about 풀케어 -->
		<div class="py-7 py-md-15 px-4 full_care">
			<div class="wrapper">
				<v-col
					class="pa-0"
					align="center"
				>
					<span class="care_tit full">
						풀케어 Full-Care
						<span class="pa-1 ml-1 flag_ready">출시예정</span>
					</span>
				</v-col>
				<p class="mt-5 mb-4 mt-md-12 mb-md-10 sub_tit tac">풀케어 제공 혜택</p>

				<HomeCustomCarefloFullBenefit />
				<HomeCustomCarefloFullAccodian />
			</div>
		</div>

		<!-- 상담연결 -->
		<div class="py-7 py-md-12 px-4 consulting">
			<div class="wrapper">
				<p class="mb-7 mb-md-10 tac sub_tit">찾는 질문이 없다면?</p>
				<HomeCustomCarefloLinkConsulting />
			</div>
		</div>
	</v-container>
</template>

<script>
import common from '@/mixins/common'
export default {
	name: 'HomeCustomCareFlo',
	metaInfo: {
		title: '케어플로 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '케어플로ㅣ동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '동화자연마루, 케어플로, 원케어, 풀케어, 무상AS, AS서비스, 품질보증, 품질보증서비스',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '현장점검부터 사후관리까지 마루 전문 기업이 만든 확실한 Care-Flo를 경험하세요.',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}/custom/careFlo`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: `${process.env.VUE_APP_BASIC_SERVER}/img_og_careflo.png`,
			},
		],
	},
	mixins: [common],
	data: () => ({
		panel: [0, 1],
	}),
	computed: {},
	watch: {},
	mounted() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.home_main {
	padding-bottom: 0 !important;
}
.visual {
	word-break: keep-all;
	background-color: $color_gray_1;
}
.sticker {
	background-color: $color_primary_3;
}
.full_care {
	background-color: #eef8f3;
}
.consulting {
	background-color: $color_gray_1;
	.sub_tit {
		font-weight: $fw_bold;
	}
}

.care_tit {
	display: table;
	padding: 8px 25px 10px;
	border-radius: 99px;
	font-size: 2.2rem;
	font-weight: $fw_bold !important;
	line-height: 1;
	&.one {
		color: $color_font;
		background-color: #eef8f3;
	}
	&.full {
		color: $color_white;
		background-color: #00ce7d;
		.flag_ready {
			display: inline-block;
			color: $color_gray_8;
			font-size: $font_normal;
			background: $color_green_1;
			border-radius: 4px;
			vertical-align: middle;
		}
	}
}
.sub_tit {
	font-size: $font_tit;
	font-weight: $fw_regular;
	color: $color_font;
}

@media all and (max-width: 959px) {
	.care_tit {
		padding: 8px 12px !important;
		font-size: $font_sub_tit !important;
		&.full {
			.flag_ready {
				font-size: $font_sm !important;
			}
		}
	}
	.sub_tit {
		font-size: $font_xl !important;
	}
}
</style>
